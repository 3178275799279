import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { CacheKeys } from '../../constants/cacheKeys'
import { useAlertContext } from '../../contexts/AlertContext'
import { useTabs } from '../../hooks/useTabs'
import { WorkQueueItemModel } from '../../models/WorkQueueItemModel'
import { getClosedWorkQueueItems, getOpenWorkQueueItems } from '../../services/workQueueService'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import WorkQueueTable from './WorkQueueTable'

const WorkQueueTabs = () => {
  const { tabIndex, handleTabChange } = useTabs()
  const { setDetails: setAlertDetails } = useAlertContext()

  const {
    isPending: isOpenItemsLoading,
    error: openItemsError,
    data: openItems,
  } = useQuery<WorkQueueItemModel[]>({
    queryKey: [CacheKeys.OpenWorkQueueItems],
    queryFn: () => getOpenWorkQueueItems(),
  })

  const {
    isPending: isClosedItemsLoading,
    error: closedItemsError,
    data: closedItems,
  } = useQuery<WorkQueueItemModel[]>({
    queryKey: [CacheKeys.ClosedWorkQueueItems],
    queryFn: () => getClosedWorkQueueItems(),
  })

  useEffect(() => {
    if (openItemsError || closedItemsError) {
      setAlertDetails({
        message: 'Please refresh the page and try again.',
        severity: AlertSeverity.ERROR,
      })
    }
  })

  return (
    <Box>
      <TabContext value={tabIndex}>
        {/* TabList should generally live here, but inside WorkQueue table to override styling */}
        <TabPanel value="0" sx={{ padding: 0 }}>
          <WorkQueueTable
            data={openItems ?? []}
            isLoading={isOpenItemsLoading}
            handleTabChange={handleTabChange}
            isOpenTable
          />
        </TabPanel>
        <TabPanel value="1" sx={{ padding: 0 }}>
          <WorkQueueTable data={closedItems ?? []} isLoading={isClosedItemsLoading} handleTabChange={handleTabChange} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default WorkQueueTabs
