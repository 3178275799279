import { WorkQueueItemModel } from '../models/WorkQueueItemModel'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getOpenWorkQueueItems = async (): Promise<WorkQueueItemModel[]> => {
  const response: ApiResponse<WorkQueueItemModel[]> = await api.get(`${Endpoints.WorkQueue}/openItems`)
  return response.data
}

export const getClosedWorkQueueItems = async (): Promise<WorkQueueItemModel[]> => {
  const response: ApiResponse<WorkQueueItemModel[]> = await api.get(`${Endpoints.WorkQueue}/closedItems`)
  return response.data
}
