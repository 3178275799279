import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { AlertNotification } from './components/AlertNotification/AlertNotification'
import TopMenuBar from './components/TopMenuBar/TopMenuBar'
import { AlertContextProvider } from './contexts/AlertContext'
import { CoverageDataProvider } from './contexts/CoverageDataContext'
import { HierarchyProvider } from './contexts/HierarchyContext'
import { NavbarProvider } from './contexts/NavbarContext'
import { PolicyDetailsDataProvider } from './contexts/PolicyDataProvider'
import { SearchProvider } from './contexts/SearchContext'
import { ClaimDetails } from './pages/ClaimDetails'
import Combo from './pages/Combo'
import { Employers } from './pages/Employers'
import PolicyDetail from './pages/PolicyDetail'
import { RatingDetails } from './pages/RatingDetails'
import { Search } from './pages/Search'
import { UnitStatDetails } from './pages/UnitStatDetails'
import { WorkQueue } from './pages/WorkQueue'
import theme from './styles/theme'

const AppRouter: React.FC = () => {
  const queryClient = new QueryClient()

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <NavbarProvider>
            <AlertContextProvider>
              <TopMenuBar />
              <AlertNotification />
              <CoverageDataProvider>
                <PolicyDetailsDataProvider>
                  <HierarchyProvider>
                    <SearchProvider>
                      <Routes>
                        <Route path="/coverages" element={<Employers />} />
                        <Route path="/coverages/:coverageId" element={<Employers />} />
                        <Route path="/policies" element={<PolicyDetail />}>
                          <Route path=":policyId" element={<PolicyDetail />} />
                        </Route>
                        <Route path="/unitstats/:unitStatId" element={<UnitStatDetails />} />
                        <Route path="/combos/:comboId" element={<Combo />} />
                        <Route path="/ratings/:ratingId" element={<RatingDetails />} />
                        <Route path="/claims/:unitStatLossId" element={<ClaimDetails />} />
                        <Route path="/search" element={<Search />} />

                        <Route path="/" element={<WorkQueue />} />
                      </Routes>
                    </SearchProvider>
                  </HierarchyProvider>
                </PolicyDetailsDataProvider>
              </CoverageDataProvider>
            </AlertContextProvider>
          </NavbarProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </Router>
  )
}

export default AppRouter
