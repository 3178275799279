import { SyntheticEvent, useState } from 'react'

interface IUseTabs {
  tabIndex: string
  handleTabChange: (_: SyntheticEvent, newValue: string) => void
}

export const useTabs = (defaultTabIndex = '0'): IUseTabs => {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex)

  const handleTabChange = (_: SyntheticEvent, newValue: string) => {
    setTabIndex(newValue)
  }

  return { tabIndex, handleTabChange }
}
